<template>
  <div v-if="!loading">
  <a-card :bordered="false" class="header-solid h-full ticket-card" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }">
    <template #title>
      <h6 class="font-semibold m-0" style="display: inline-block" v-if="schedule&&schedule.tickets.length">List of tickets</h6>
        <a-button size="small" :style="{'float':'right'}" @click="showModal=true,selectedTicket='',editMode=''">
        <a-icon type="plus" /> Add New Ticket Info
      </a-button>
      <FormModal v-if="showModal" :visible="showModal" :eventId="eventId" :scheduleId="schedule._id" :selectedItem="selectedTicket" :editMode="editMode"
                 @formSuccess="formSuccess()" @cancel="cancelForm()"></FormModal>
    </template>
    <a-row  v-if="schedule&&schedule.tickets.length" :gutter="[24, 24]" >
      <a-col :span="24" :key="index" v-for="(ticket,index) in schedule.tickets">
        <a-card :bordered="false" class="card-billing-info" :loading="loading">
          <div class="col-info">
            <a-descriptions :column="1">
              <span slot="title" style="font-size: 16px;font-weight: 600;color: #f05537;">
                 <a-avatar shape="square" slot="avatar" style="margin-right: 5px" :style="{'background-color': ticket.seller.backgroundColor?ticket.seller.backgroundColor:'#f05537','color': ticket.seller.textColor?ticket.seller.textColor:'#ffffff'}">
                  {{ (ticket&&ticket.seller&&ticket.seller.shortCode ? ticket.seller.shortCode : 'X') }}
                </a-avatar>
<!--                <a-icon type="shopping-cart" style="font-size:1.2rem" />-->
                {{ticket.seller.title}}
              </span>
              <a-descriptions-item>
                <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-map-pin"><path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z"></path><circle cx="12" cy="10" r="3"></circle></svg>
                {{ ticket.location?ticket.location:'N/A' }}
              </a-descriptions-item>
              <a-descriptions-item>
                {{ ticket.currency + ' ' + ticket.minPrice + (ticket.maxPrice?' - ' + ticket.maxPrice:'') }} | <a-icon type="calendar" style="margin-right: 8px" /><span v-if="new Date(ticket.startDate).toDateString()!=new Date(ticket.endDate).toDateString()">{{ ticket.startDate | moment("D MMM YYYY, hh:mm A") }} - {{ ticket.endDate | moment("D MMM YYYY, hh:mm A") }}</span>
                <span v-else>{{ ticket.startDate | moment("D MMM YYYY, hh:mm A") }} - {{ ticket.endDate | moment("hh:mm A") }}</span>
              </a-descriptions-item>
              <a-descriptions-item label="Source URL">
                <a target="_blank" :href="ticket.url">{{ ticket.url?ticket.url:'N/A' }}</a>
              </a-descriptions-item>
              <a-descriptions-item label="Added by">
                {{ ticket.user.name }}
              </a-descriptions-item>
            </a-descriptions>
          </div>
          <div class="col-action">
            <a-button type="link" size="small" @click="editMode = true,selectedTicket = ticket,showModal = true;">
              <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="fill-muted" d="M13.5858 3.58579C14.3668 2.80474 15.6332 2.80474 16.4142 3.58579C17.1953 4.36683 17.1953 5.63316 16.4142 6.41421L15.6213 7.20711L12.7929 4.37868L13.5858 3.58579Z" fill="#111827"/>
                <path class="fill-muted" d="M11.3787 5.79289L3 14.1716V17H5.82842L14.2071 8.62132L11.3787 5.79289Z" fill="#111827"/>
              </svg>
              <span class="text-dark">EDIT</span>
            </a-button>
            <a-button type="link" size="small" @click="showDelete(ticket._id,schedule._id)">
              <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path class="fill-danger" fill-rule="evenodd" clip-rule="evenodd" d="M9 2C8.62123 2 8.27497 2.214 8.10557 2.55279L7.38197 4H4C3.44772 4 3 4.44772 3 5C3 5.55228 3.44772 6 4 6L4 16C4 17.1046 4.89543 18 6 18H14C15.1046 18 16 17.1046 16 16V6C16.5523 6 17 5.55228 17 5C17 4.44772 16.5523 4 16 4H12.618L11.8944 2.55279C11.725 2.214 11.3788 2 11 2H9ZM7 8C7 7.44772 7.44772 7 8 7C8.55228 7 9 7.44772 9 8V14C9 14.5523 8.55228 15 8 15C7.44772 15 7 14.5523 7 14V8ZM12 7C11.4477 7 11 7.44772 11 8V14C11 14.5523 11.4477 15 12 15C12.5523 15 13 14.5523 13 14V8C13 7.44772 12.5523 7 12 7Z" fill="#111827"/>
              </svg>
              <span class="text-danger">DELETE</span>
            </a-button>
          </div>
        </a-card>
      </a-col>
    </a-row>
    <a-row v-if="schedule&&!schedule.tickets.length" :gutter="[24, 24]">
      <a-col :span="24">
        <h6 class="font-semibold m-0 text-center">No tickets created yet..
        </h6>
      </a-col>
    </a-row>

  </a-card>
  </div>
  <div v-else>

    <a-row :gutter="[24, 24]">
      <a-col :span="24" >
        <a-skeleton active />
        <a-skeleton active />
        <a-skeleton active />
      </a-col>
    </a-row>
  </div>
  <!-- / Billing Information Card -->

</template>

<script>
import FormModal from './components/TicketFormModal'
export default ({
  components:{FormModal},
  props:['schedule','eventId'],
  data() {
    return {
      loading:false,
      showModal: false,
      editMode: false,
      selectedTicket:''

    }
  },
  methods:{
    formSuccess() {
      this.showModal = false;
      this.editMode = false
    },
    cancelForm() {
      this.showModal = false;
      this.editMode = false
    },
    eventInfo(){
      this.loading=true
      this.$store.dispatch("FETCH_EVENT",{id:this.$route.params.id})
          .then((res)=>{
          })
          .finally(()=>{
            this.loading=false
          })
    },
    showDelete(id,schedule_id) {
      this.$confirm({
        title: 'Are you sure want to delete this ticket?',
        content: 'This process is irreversible. Are you sure want to continue?',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: () => {
          this.loading=true

          this.$store.dispatch("DELETE_TICKET", {id:id,schedule_id:schedule_id,event_id:this.eventId})
              .then((res) => {
                this.$message.success(res.data.message);
              })
              .catch((err) => {
                // this.$message.error(err.response.data.message);
              })
              .finally(() => {
                this.loading=false

              });
        },
      });
    },

  },
  computed: {
    // schedule(){
    //   return this.$store.getters.selectedEvent
    // }
  },
  mounted() {
    // this.eventInfo()
  },
  beforeCreate() {
  }
})

</script>