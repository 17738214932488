<template>
  <div>
    <a-modal
        :width="900"
        :destroyOnClose="true"
        :confirm-loading="confirmLoading"
        :dialog-style="{ top: '20px'}"
        :visible="visible" :title="title" okText="Submit" @ok="handleOk" @cancel="cancelForm">
      <a-form layout='vertical' :form="form" @submit="handleOk">
        <a-form-item label='Ticket Seller'>
          <a-select
              show-search
              allowClear
              placeholder="Select a seller"
              option-filter-prop="children"
              :filter-option="filterOptionSeller"
              :loading="loadingSellers"
              v-decorator="[
          'seller',
          {
            rules: [{ required: true, message: 'Please select a seller!' }],
           initialValue:selectedItem&&selectedItem.seller?selectedItem.seller._id:undefined
           },
        ]"
          >
            <div slot="dropdownRender" slot-scope="menu">
              <v-nodes :vnodes="menu" />

              <a-divider style="margin: 4px 0;" />
              <div
                  style="padding: 8px 8px; cursor: pointer;"
                  @click="showModal=true"
              >
                <a-icon type="plus" /> Add New Seller
              </div>
            </div>
            <a-select-option :disabled="editMode?(seller.status?false:true):false"
                             v-if="!editMode?(seller.status):true" :value="seller._id" v-for="(seller,index) in getAllSellers" :key="index">
                           <span style="margin-right: 5px;align-items: center" role="img" >
                             <a-avatar shape="square" style="margin-right: 5px" :style="{'background-color': seller.backgroundColor?seller.backgroundColor:'#f05537','color': seller.textColor?seller.textColor:'#ffffff'}">
                  {{ (seller&&seller.shortCode ? seller.shortCode : 'X') }}
                </a-avatar>
<!--                <a-avatar shape="square" :src="url+seller.image"></a-avatar>-->
      </span>
              {{seller.title}}
            </a-select-option>
          </a-select>
        </a-form-item>
<!--        <a-form-item label='Seller Location'>-->
<!--          <a-input-->
<!--              placeholder="Input full address"-->
<!--              v-decorator="[-->
<!--              'location',-->
<!--               { rules: [{ required: true, message: 'Seller location is required' }] ,initialValue:selectedItem.location,-->
<!--              }-->
<!--            ]"-->
<!--          />-->
<!--        </a-form-item>-->
        <SellerFormModal v-if="showModal" :visible="showModal"
                   @formSuccess="formSuccess" @cancel="cancelSellerForm"></SellerFormModal>
        <a-row :gutter="24" type="flex" >

          <!-- Authors Table Column -->
          <a-col :span="6">
            <a-form-item label='Currency'>
              <a-select
                  v-decorator="[
          'currency',
          {
            rules: [{ required: true, message: 'Please select a currency!' }],
           initialValue:selectedItem&&selectedItem.currency?selectedItem.currency:'GBP(£)'
           },
        ]"
              >
                <a-select-option value="GBP(£)">
                  GBP(£)
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="9">
        <a-form-item label='Minimum Ticket Price'>
          <a-input
              placeholder="Input minimum ticket price"
              v-decorator="[
              'minPrice',
               { rules: [{ required: true, message: 'Ticket Price is required' }] ,initialValue:selectedItem.minPrice,
              }
            ]"
          />
        </a-form-item>
          </a-col>
          <a-col :span="9">
          <a-form-item label='Maximum Ticket Price'>
          <a-input
              placeholder="Input maximum ticket price"
              v-decorator="[
              'maxPrice',
               { initialValue:selectedItem.maxPrice,
              }
            ]"
          />
        </a-form-item>
          </a-col>
        </a-row>

        <a-row :gutter="24" type="flex" >

          <!-- Authors Table Column -->
          <a-col :span="12">
            <a-form-item label='Selling Start Date & Time'>
              <a-date-picker
                  :disabled-date="disabledStartDate"
                  show-time
                  format="YYYY-MM-DD HH:mm"
                  placeholder="Start Date Time"
                  @openChange="handleStartOpenChange"
                  v-decorator="[

              'startDate',
                        { rules: [{ required: true, message: 'Start Date is required' }] ,
                                                initialValue:selectedItem.startDate,

              }
            ]"
              />

            </a-form-item>
          </a-col>

          <a-col :span="12" >
            <a-form-item label='End Date & Time'>
              <a-date-picker
                  :disabled-date="disabledEndDate"
                  show-time
                  format="YYYY-MM-DD HH:mm"
                  placeholder="End Date Time"
                  :open="endOpen"
                  @openChange="handleEndOpenChange"
                  v-decorator="[

              'endDate',
                        { rules: [{ required: true, message: 'End Date is required' }],
                        initialValue:selectedItem.endDate,
              }
            ]"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label='Physical Location'>
          <a-input
              :auto-size="{ minRows: 3, maxRows: 6 }"
              type='textarea'
              placeholder="Enter a physical location where ticket is sold"
              v-decorator="['location',{
                initialValue:selectedItem?selectedItem.location:'',
              }]"
          />
        </a-form-item>
        <a-form-item label='Source URL'>
          <a-input
              :auto-size="{ minRows: 3, maxRows: 6 }"
                  type='textarea'
              placeholder="Enter an online link where ticket is sold"
              v-decorator="['url',{
                initialValue:selectedItem?selectedItem.url:'',
              }]"
          />
        </a-form-item>
        <a-form-item class='collection-create-form_last-form-item'>
          <a-radio-group
              v-decorator="[
              'status',
              {
                initialValue: selectedItem?selectedItem.status.toString():'true',
              }
            ]"
          >
            <a-radio value='true'>Active</a-radio>
            <a-radio value='false'>In-active</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import moment from "moment";
import SellerFormModal from "../../seller/FormModal";

export default {
  components: {SellerFormModal, VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },},

  props: ['editMode', 'visible','selectedItem','eventId','scheduleId'],
  data() {
    return {
      loadingSellers:false,
      url: process.env.VUE_APP_SERVER_URL,
      fileList: [],
      confirmLoading: false,
      form: this.$form.createForm(this),
      id:'',
      startDate:'',
      endDate:'',
      startValue: null,
      endValue: null,
      endOpen: false,
      showModal:false,

      // visible: false,
    };
  },
  computed: {
    title() {
      return this.editMode ? 'Edit Ticket Detail' : 'Create New Ticket Detail'
    },
    getAllSellers(){
      return this.$store.getters.getAllSellers
    },
  },
  methods: {
    formSuccess(){
      this.allFetchSellers();
      this.showModal=false
      // this.$emit('formSuccess')
    },
    cancelSellerForm(){
      this.showModal=false
    },
    allFetchSellers(){
      this.loadingSellers=true
      this.$store.dispatch("FETCH_ALL_TICKET_SELLERS")
          .then(()=>{
            this.loadingSellers=false
          })
    },
    filterOptionSeller(input, option) {
      return (
          option.componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    disabledStartDate(current) {
      return current && current < moment().startOf('day');
    },
    disabledEndDate(endValue) {
      const startValue = this.form.getFieldValue('startDate');
      if (!endValue || !startValue) {
        return false;
      }
      return startValue.valueOf() >= endValue.valueOf();
    },
    handleStartOpenChange(open) {
      if (!open) {
        this.endOpen = true;
      }
    },
    handleEndOpenChange(open) {
      this.endOpen = open;
    },
    handleOk(e) {
      e.preventDefault();
      if (this.confirmLoading)
        return
      this.form.validateFields((err, values) => {
        if (!err) {
         this.confirmLoading = true
          if (this.editMode){
            values._id=this.selectedItem._id
            this.$store.dispatch("UPDATE_TICKET", {form:values,event_id:this.eventId,schedule_id:this.scheduleId})
                .then((res) => {
                  this.fileList=[]
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });
          }
          else {
            this.$store.dispatch("CREATE_TICKET", {form:values,event_id:this.eventId,schedule_id:this.scheduleId})
                .then((res) => {
                  this.fileList=[]
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });

          }

        }
      });
    },
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      if (this.fileList.length < 1)
        this.fileList = [...this.fileList, file];

      return false;
    },
    cancelForm(){
      this.fileList=[];
      this.form.resetFields();
      this.confirmLoading=false;
      this.$emit('cancel')
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
  mounted() {
    this.allFetchSellers()
    // setTimeout(()=>{
      if (this.editMode&&Object.keys(this.selectedItem).length!=0){
        this.id=this.selectedItem._id
    }
    // },100)

      // this.form={...this.selectedItem}
  }
};
</script>
<style>
.blocked {
  pointer-events: none;
  opacity: 0.5;
}

</style>